* {
  -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
  color-adjust: exact !important;                 /* Firefox */
}

body {
  background: white;
  padding: 0px;
  margin: 0px;
  overflow: scroll;
  font-family: "arial";
  user-select: none;
}

input {
  font-size: 18px;
}

textarea:focus, input:focus{
    outline: none;
}

.scrollable-container {
  overflow: scroll;
}

.top-bar {
  background-color: rgb(62, 62, 62);
  width: 100%;
  height: 40px;
  padding: 6px;
  box-sizing: border-box;
}

.title {
  float: right;
  color: white;
}

.beta {
  color: #aaa;
}

.newButton, .permalinkButton {
  height: 28px;
  /*margin-left: 20px;*/
  border-radius: 3px;
  cursor: pointer;
  background-color: white;
  float: left;
}

.newButton {
  background-image: linear-gradient(90deg, #3874e2 0%, #0f91b3 17%, #489a2b 34%, #c19b03 51%, #ff842e 68%, #cc51a6 85%, #ce1c1c 100%);
  padding: 3px;
  border: none;
}

.newButtonInternal {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 2px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
}

.top-row {
  height: 34px;
  position: relative;
}

.top-row-static {
  float: left;
  background-color: #f5f5f5;
  text-align: center;
  color: #3e3d3d;
  box-sizing: border-box;
  border-style: solid;
  border-width: 4px 2px 4px 2px;
  line-height: 26px;
  border-color: white;
  font-size: 13px;
}

.top-row-static:focus {
  outline: none;
}

.top-row-static-first {
  background-color: #cecaca;
  cursor: text;
}

.item {
  padding: 10px 18px 10px 18px;
  color: white;
  border-color: white;
  border-width: 1px;
  border-radius: 7px;
  border-style: solid;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: move;
}

.item:focus {
  outline: none;
}

.deleteitem {
  position: absolute;
  color: #3874e2;
  top: 1px;
  right: 1px;
  font-size: 10px;
  cursor: pointer;
  background-color: white;
  padding: 4px;
  border-radius: 4px;
  width: 5px;
  height: 6px;
  line-height: 6px;
  z-index: 1;
  margin-top: 4px;
  margin-right: 4px;
}

.arrow_box {
  position: relative;
  background: #fff;
  border: 1px solid #888;
  border-radius: 3px;
}
.arrow_box:after, .arrow_box:before {
  bottom: 100%;
  left: 35px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 5px;
  margin-left: -5px;
}
.arrow_box:before {
  border-color: rgba(136, 136, 136, 0);
  border-bottom-color: #888;
  border-width: 6px;
  margin-left: -6px;
}

.show-medium {
  display: block;
}

@media screen and (max-width: 480px) {
  .show-medium {
    display: none;
  }
}
